export const INTERVIEW = 1
export const X_MOMENT = 2
export const PARTNERSHIP = 3
export const NEGOTIATION = 4
export const INDIVIDUAL_INTERVIEW = 5
export const GROUP_DINAMIC = 6
export const PRE_JOB_SAMPLE = 7
export const FINAL_APPROVAL = 8

export const PCL_APPOINTMENTS = [INTERVIEW, X_MOMENT, PARTNERSHIP, NEGOTIATION]
export const PPN_APPOINTMENTS = [GROUP_DINAMIC, INDIVIDUAL_INTERVIEW, PRE_JOB_SAMPLE, FINAL_APPROVAL]