import { render, staticRenderFns } from "./AppointmentsList.vue?vue&type=template&id=996d088a&scoped=true"
import script from "./AppointmentsList.vue?vue&type=script&lang=js"
export * from "./AppointmentsList.vue?vue&type=script&lang=js"
import style0 from "./AppointmentsList.vue?vue&type=style&index=0&id=996d088a&prod&lang=scss&scoped=true"
import style1 from "./AppointmentsList.vue?vue&type=style&index=1&id=996d088a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996d088a",
  null
  
)

export default component.exports